<template>
  <section>
    <b-card class="event-card">
      <div class="d-flex justify-content-between w-100 my-1 mb-3 pl-1">
        <div class="d-flex align-items-center float-left">
          <a class="mr-50" @click="$router.go(-1)">
            <feather-icon icon="ChevronLeftIcon" size="20" />
          </a>
          <h3 class="m-0 font-weight-600">
            {{ appTitle || $t("events.title") }}
            <span class="dot"> • </span>
            <span class="gray">{{ selectedFilter.label }}</span>
          </h3>
        </div>
        <div class="d-flex align-items-center">
          <v-select
            v-model="selectedFilter"
            class="pl-1 filter-select d-inline-block"
            :clearable="false"
            :searchable="false"
            :options="filterOptions"
            @input="changeSelector"
          />
          <widget-actions
            v-if="!agenda"
            class="d-flex justify-content-end ml-1"
            item-type="membership-events"
            :show-add="canAdd"
            :show-list-type="false"
            :show-list="true"
            :show-filter="false"
            :is-search-needed="true"
            :show-maximize="false"
            @updateSearch="searchItems"
            @openMediaModal="isAddingMedia = true"
            @toggleListType="toggleListType"
          />
          <!-- <feather-icon
            v-if="!agenda"
            icon="CalendarIcon"
            size="20"
            class="text-primary edit-icon ml-1"
            role="button"
            @click="agenda = !agenda"
          />
          <feather-icon
            v-if="agenda"
            icon="GridIcon"
            size="20"
            class="text-primary edit-icon ml-1"
            role="button"
            @click="agenda = !agenda"
          /> -->
        </div>
      </div>

      <!-- Loading -->
      <div v-if="isLoading">
        <b-spinner variant="primary" class="mx-auto mt-5 d-block" />
      </div>

      <!-- Container with data -->
      <div v-else-if="items.length > 0 && !agenda" class="cards-container">
        <div>
          <!-- Items Loop -->
          <b-row>
            <b-col
              v-for="item in items"
              :key="item.key"
              :class="
                item.isPublished || (!item.isPublished && canAdd)
                  ? 'mb-sm-50'
                  : 'd-none'
              "
            >
              <event-item
                v-if="item.isPublished || (!item.isPublished && canAdd)"
                :item="item"
              />
            </b-col>
            <!-- Load More items -->

            <div
              v-if="items.length < itemsData.meta.total && searchInput === ''"
              class="w-100 float-left"
            >
              <b-button
                v-if="!isLoadingNextPage"
                class="center-x my-3"
                variant="outline-primary"
                @click="handleLoadOfNewItems"
              >
                {{ $t("action.load-more") }}
              </b-button>
              <b-button v-else disabled class="center-x my-3" variant="link">
                <b-spinner variant="primary" label="Loading..." />
              </b-button>
            </div>
          </b-row>
        </div>
      </div>
      <events-agenda
        v-else-if="agenda"
        :forced-events="items"
        :is-membership="true"
      />
      <!-- Container without data: Placeholder -->
      <b-row v-else class="horizontal-placeholder">
        <b-col cols="12">
          <img :src="eventsPlaceholder" />
        </b-col>
        <b-col cols="12">
          <p class="text-primary">
            {{ $t("available.message", { itemName: $t("events.title") }) }}
          </p>
        </b-col>
      </b-row>

      <!-- Create event modal -->
      <b-modal
        id="modal-create-membership-events"
        v-model="isModalVisible"
        centered
        hide-footer
        size="lg"
        @show="getEventTypes"
      >
        <template #modal-header>
          <language-selector-header
            :title="$t('events.actions.create-event')"
            @closeModal="closeModal"
            @selectLanguage="(language) => (selectedLanguage = language)"
          />
        </template>
        <!-- Loading -->
        <div v-if="isLoadingTypes">
          <b-spinner variant="primary" class="mx-auto my-3 d-block" />
        </div>
        <membership-event-create-modal
          v-if="typeOptions.length > 0"
          :type-options="typeOptions"
          item-type="events"
          :selected="selectedLanguage"
          @close-modal="isModalVisible = false"
        />
      </b-modal>
    </b-card>
  </section>
</template>

<script>
import { getImageResource } from "@/@core/utils/image-utils";
import ListContainerWidget from "@core/widgets/ListContainerWidget.vue";
import WidgetActions from "@/@core/components/widget-actions/WidgetActions.vue";
import { quillEditor } from "vue-quill-editor";
import moment from "moment";
import WidgetLayoutMixin from "@core/mixins/widgets/WidgetLayoutMixin";
import Placeholder from "@/assets/images/placeholders/light/placeholder-dark.jpeg";
import vSelect from "vue-select";
import FileTypes from "@/@core/constants/FileTypes";
import { checkPermissions } from "@/@core/utils/roles-utils";
import EventsPlaceholder from "@/assets/images/placeholders/light/events.svg";
import EventsAgenda from "@/views/apps/events/components/EventsAgenda";
import { translateTranslationTable } from "@/@core/libs/i18n/utils";
import { ENABLED_APPS_GETTERS } from "@/store/enabled-apps/enabled-apps-store-constants";
import LanguageSelectorHeader from "@core/components/modal/LanguageSelectorHeader.vue";
import EventItem from "./EventItem.vue";
import MembershipEventCreateModal from "./MembershipEventCreateModal.vue";

/* const EventsFilters = Object.freeze({
  all: () => ({
    orderByDate: OrderBy.ASC,
  }),
  upcoming: () => ({
    orderByDate: OrderBy.ASC,
    upcomingEvents:true,
    sinceStartDate: Date.now(),
  }),
  past: () => ({
    orderByDate: OrderBy.DESC,
    pastEvents:true,
    untilEndDate: Date.now() - 1,
  }),
}); */
export default {
  name: "MembershipEventsList",
  components: {
    ListContainerWidget,
    WidgetActions,
    quillEditor,
    vSelect,
    EventItem,
    MembershipEventCreateModal,
    EventsAgenda,
    LanguageSelectorHeader,
  },
  mixins: [WidgetLayoutMixin],
  data() {
    return {
      isLoading: false,
      isLoadingTypes: false,
      isLoadingNextPage: false,
      lastLoadedPage: 1,
      loadingNext: false,
      memberForMeeting: {},
      isRequestMeetingVisible: false,
      formInput: {},
      itemImage: null,
      imageSrc: null,
      searchInput: "",
      results: [],
      Placeholder,
      typeOptions: [],
      isModalVisible: false,
      toggleListType: false,
      perPage: 30,
      selectedFilter: { label: this.$t("events.list.filter.all"), code: 0 },
      agenda: false,
      selectedLanguage: this.currentLocale,
    };
  },
  computed: {
    eventsApp() {
      return this.$store.getters[ENABLED_APPS_GETTERS.enabledApps].membershipEvents;
    },
    appTitle() {
      return translateTranslationTable(
        this.$store.state.locale.currentLocale,
        this.eventsApp?.customizationName
      );
    },
    filterOptions() {
      return [
        { label: this.$t("events.list.filter.all"), code: 0 },
        { label: this.$t("events.list.filter.present"), code: 1 },
        { label: this.$t("events.list.filter.present-upcoming"), code: 2 },
        { label: this.$t("events.list.filter.upcoming"), code: 3 },
        { label: this.$t("events.list.filter.past"), code: 4 },
      ];
    },
    FileTypes() {
      return FileTypes;
    },
    isStaff() {
      return this.$store.getters.currentCollective.isStaff;
    },
    loggedMemberRoles() {
      return this.$store.getters.loggedMemberRoles;
    },
    canEdit() {
      return (
        this.loggedUser.key === this.item?.ownedByUser?.key ||
        this.isStaff ||
        checkPermissions(
          "update",
          "event",
          this.loggedMemberRoles,
          this.$store.getters.currentCollective
        )
      );
    },
    collective() {
      return this.$store.getters.currentCollective;
    },
    // Load data from store
    itemsData() {
      return this.$store.getters.communitiesOthers.events;
    },
    items() {
      const itemsToIterate = this.searchInput
        ? this.results
        : this.itemsData?.unpaginated;
      return itemsToIterate;
    },
    canAdd() {
      return checkPermissions(
        "create",
        "event",
        this.loggedMemberRoles,
        this.collective
      );
    },
    canCreate() {
      return (
        this.isStaff ||
        checkPermissions(
          "create",
          "event",
          this.loggedMemberRoles,
          this.collective
        )
      );
    },
    eventsPlaceholder() {
      return EventsPlaceholder;
    },
    currentLocale() {
      return this.$store.state.locale.currentLocale;
    },
    typeKey() {
      return this.$route.query.display;
    },
    displayOption() {
      if (this.typeKey) {
        return this.filterOptions.find(
          (option) => option.code === +this.typeKey
        );
      }
      return this.filterOptions[0];
    },
  },
  watch: {
    itemImage(newValue, oldValue) {
      if (newValue !== oldValue) {
        if (newValue) {
          this.base64Encode(newValue)
            .then((value) => {
              this.imageSrc = value;
            })
            .catch(() => {
              this.imageSrc = null;
            });
        } else {
          this.imageSrc = null;
        }
      }
    },
    typeKey(newValue, oldValue) {
      if (newValue !== oldValue) {
        this.isLoading = true;
        this.selectedFilter = this.displayOption;
        this.fetchData();
        this.isLoading = false;
      }
    },
  },

  // Fetch data when component is created!
  async created() {
    this.isLoading = true;
    this.selectedFilter = this.displayOption;
    await this.fetchData();
    await this.getInstalledApps();
    this.isLoading = false;
  },
  methods: {
    async getInstalledApps() {
      await this.$store.dispatch("getInstalledApps");
    },
    getImageSrc(url) {
      return getImageResource(url);
    },
    timestampToTime(timestamp) {
      return moment(timestamp).format("DD-MM-YYYY");
    },
    closeModal() {
      this.$bvModal.hide("modal-create-membership-events");
    },
    base64Encode(data) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(data);
        reader.onload = () => resolve(reader.result);
        reader.onerror = (error) => reject(error);
      });
    },
    // Call store action (with dispach) to load data from backend
    async fetchData(force = true) {
      this.updateBreadcrumbs();
      const requestConfig = {
        communityParentSlug: this.$store.getters.currentCollective.slug,
        orderByDate: 1,
        displayOptions: this.selectedFilter.code,
        isEvent: true,
      };
      await this.$store.dispatch("getItems", {
        itemType: "communities/simply",
        customName: "communitiesOthers",
        storedKey: "events",
        page: this.lastLoadedPage,
        perPage: this.perPage,
        forceAPICall: force,
        requestConfig,
      });
    },
    changeSelector() {
      if (this.selectedFilter.code > 0) {
        this.$router.push({
          name: "events",
          query: { display: this.selectedFilter.code },
        });
      } else {
        this.$router.push({ name: "events" });
      }
      this.lastLoadedPage = 1;
      this.fetchData();
    },
    // Load more items
    async handleLoadOfNewItems() {
      const { total } = this.itemsData.meta;
      if (!this.isLoading && this.items.length <= total) {
        this.isLoadingNextPage = true;
        this.lastLoadedPage += 1;
        await this.fetchData(false);
        this.isLoadingNextPage = false;
      }
    },
    searchItems(value) {
      this.searchInput = value;
      const searchTerm = value.toLowerCase().trim();
      if (!searchTerm) {
        return;
      }
      this.results = this.itemsData.unpaginated.filter((item) =>
        item.name.toLowerCase().trim().includes(searchTerm)
      );
    },
    translate(field) {
      return translateTranslationTable(this.currentLocale, field);
    },
    async getEventTypes() {
      this.isLoadingTypes = true;
      const response = await this.$store.dispatch("getItems", {
        itemType: "types",
        storedKey: "event",
        page: 1,
        perPage: 50,
        requestConfig: { modelType: "event", getCommunityTypes: 1 },
      });
      if (response) {
        for (const typeOption of response.data) {
          Object.values(typeOption.name).length > 0
            ? this.typeOptions.push({
                code: typeOption.id,
                type: this.translate(typeOption.name),
              })
            : "";
        }
      }
      this.isLoadingTypes = false;
    },
    updateBreadcrumbs() {
      const breadcrumbs = [
        {
          text: this.appTitle || this.$t("events.page-title.event-list"),
          active: true,
        },
      ];
      this.$store.commit("app/SET_BREADCRUMBS", breadcrumbs);
    },
  },
};
</script>

<style lang="scss" scoped>
@import "~@core/scss/base/bootstrap-include";
.card-img {
  height: 200px !important;
}
.event-card {
  min-height: 60vh;
}
.dot {
  color: #48a2a9;
  margin: 0 5px;
}
.gray {
  color: #82818d;
  font-size: 16px;
}
.filter-select {
  min-width: 150px;
  @include media-breakpoint-up(md) {
    min-width: 250px;
  }
}
</style>
